.mainContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 85%;
  max-width: 85%;
  overflow: auto;
  margin-top: 75px;
  margin-left: 15%;
}

.mainContainerPadding {
  padding: 24px;
  max-width: calc(85% - 48px);
}
