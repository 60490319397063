.worklogcontainer {
  width: 50% !important;
  margin-left: 2% !important;
}

.worklogbox {
  border: 2px solid grey;
  border-radius: 10px;
  margin-top: 3%;
  display: flex;
}

.worklogleftbox {
  width: 85%;
}

.worklogboxtitles {
  display: flex !important;
  padding-left: 20px !important;
}

.horizontalline {
  width: 83% !important;
  height: 0.75px !important;
  background-color: grey !important;
  margin-left: 20px !important;
}

.actionbox {
  width: 10%;
  justify-content: center;
  display: flex;
}

.deleteicon {
  cursor: pointer;
  align-self: center;
  color: #ab0f0f;
}

.title {
  width: 30%;
  font-weight: bold;
}

.descriptiontext {
  color: white;
  font-size: 18.5;
  line-height: 1.2;
}

.datetext {
  width: 30%;
}

.tooltipbox {
  width: 40% !important;
}
