.background {
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-color: #f8f8f8 !important;
}

.mainContainer {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.logo {
  width: 89%;
  margin: 0 24px 30px 25px;
  object-fit: contain;
}

.epico {
  width: 120px;
  margin: 10px 0 30px 0;
}

.formContainer {
  width: 360px;
  height: 400px;
  margin: 30px 0 121px;
  padding: 28px 20px 21px;
  border-radius: 8px;
  background-color: var(--color-secundary-theme);
}

.formContainer input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset;
}

.recoverFormContainer {
  width: 360px;
  height: 300px;
  margin: 30px 0 121px;
  padding: 28px 20px 21px;
  border-radius: 8px;
  background-color: var(--color-secundary-theme);
}

.forgottenPasswordText {
  color: white;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: 0;
  font-family: Montserrat-Regular, sans-serif !important;
  margin: 36px 99px 0 2px;
}

.button {
  margin: 28px 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
